<template>
  <div>
  
    <div class="section has-background-primary">
      <div class="container has-text-centered has-text-white">
        <div class="columns is-centered">
          <div class="column is-8">
            <div class="content">
              
            <p>
            <i class="far fa-3x fa-question-circle"></i>&nbsp;
            <i class="fas fa-2x fa-mouse-pointer"></i>
            </p>
            </div>
            <h1 class="is-2 title is-800 has-text-white is-spaced">Area de Sistemas</h1>
            <h1 class="is-4 subtitle is-800 has-text-white">Integración con aplicaciones propias de las organizaciones</h1>
            <div class="content">
  
            <p>
              Si te interesa integrar nuestro firmador a tus aplicaciones de forma transparente para el usuario, comunicate con nosotros al helpdesk enlace
            </p>
            </div>
            <div class="buttons is-centered">
              <a href="https://docs.signar.com.ar/firmador" target="_blank" class="button is-white"><i class="fas fa-external-link-alt"></i>&nbsp;Documentación</a>
              <a href="https://soporte.lyris.com.ar" target="_blank" class="button is-white is-outlined"><i class="fas fa-phone"></i>&nbsp;Contactar soporte</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-8">
            <h1 class="is-3 title is-800 has-text-primary has-text-centered">Resumen técnico</h1>
  
            <table class="table is-bordered is-fullwidth">
              <tbody>
                <tr>
                  <th>Sistemas operativos</th>
                  <td>
                    <p><i class="fas fa-caret-right"></i>&nbsp;Windows 8+ (x64)</p>
                    <p><i class="fas fa-caret-right"></i>&nbsp;Linux</p>
                  </td>
                </tr>
                <tr>
                  <th>Requisitos</th>
                  <td>Java 8 (Incluido en el instalador)</td>
                </tr>
                <tr>
                  <th>Estandares soportados</th>
                  <td>
                    <p><i class="fas fa-caret-right"></i>&nbsp;PAdES (Firma para archivos PDF)</p>
                    <p><i class="fas fa-caret-right"></i>&nbsp;XAdES (Firma XML) *</p>
                    <br>
                    <p class="is-size-7 has-text-info">* Firma de documentos de cualquier extensión<br>Ej: .doc, .xls, .pdf, .avi, .jpg, .jpeg, etc</p>
                  </td>
                </tr>
                <tr>
                  <th>Metodos de ejecución<br>**</th>
                  <td>
                    <p><i class="fas fa-caret-right"></i>&nbsp;Independiente:</p>
                    <p class="is-size-7">Ejecución individual y autónoma de firma de documentos electrónicos personales</p>
                    <br>
                    <p><i class="fas fa-caret-right"></i>&nbsp;Dependiente</p>
                    <p class="is-size-7">Invocacipón del firmador por protocolo, uniendo el proceso de firmado con otros sistemas de la empresa de forma transparente y directa.</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <br>
             <div class="buttons is-centered">
              <a href="https://docs.signar.com.ar/firmador" target="_blank" class="button is-primary"><i class="fas fa-external-link-alt"></i>&nbsp;Documentación</a>
              <a href="https://soporte.lyris.com.ar" target="_blank" class="button is-primary is-outlined"><i class="fas fa-phone"></i>&nbsp;Contactar soporte</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Los anchors de 'Ficha técnica' se deshabilitaron después de hablar con Matías debido a que se debería actualizar la ficha técnica a una versión que sea visible para los clientes (sin códigos ni control del documento). Mientras tanto, se encontrarán deshabilitados.
}
</script>
