<template>
  <footer class="footer">
    <div class="container">
      
    <div class="content has-text-centered">
      <br>
      <div class="columns is-centered">
        <div class="column is-8 has-text-centered has-text-left-desktop">
          <div class="is-flex">
            <a href="https://lyris.com.ar"><img src="@/assets/lyris-color.svg" class="image logo-lyris" width="160" alt="Lyris IT"></a>
          </div>
          <br>
          <p class="is-size-7">
            <b>Acerca de LYRIS</b> Proveemos servicios de consultoría sobre aplicación de firma electrónica y digital en procesos administrativos, implementación de recibos de sueldo digitales de acuerdo a la normativa nacional del MTEySS, despapelización en organizaciones/empresas con valor legal y realizamos desarrollo y provisión de software específico para las implementaciones de firma digital.
          </p>
        </div>
        <div class="column is-4 has-text-centered has-text-right-desktop is-flex is-justify-content-space-around is-align-items-center">
          <a href="https://lyris.com.ar"><img src="@/assets/signar-logo.svg" class="image logo-signar" width="160" alt="Signar (R)"></a>
        </div>
      </div>
    </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.logo-lyris{
  margin: 0 auto 0 0;
  filter: grayscale(100%); // Make the image appear gray by default
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: none; // Remove the filter on hover to show the colored image
    scale: 1.1;
  }
  @media screen and (max-width: 769px){
     margin: 0 auto;
  }
}
.logo-signar{
  margin: 0 auto;
  filter: grayscale(100%); // Make the image appear gray by default
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: none; // Remove the filter on hover to show the colored image
    scale: 1.1;
  }
  @media screen and (min-width: 1024px){
    margin: 0 0 0 auto;
  }
}
</style>