<template>
  <div id="app">
    <nav class="navbar">
      <div class="container is-flex">

    <div class="navbar-brand">
      <a class="navbar-item logo" href="https://lyris.com.ar">
        <img src="@/assets/lyris-color.svg" width="120">
      </a>
      <a href="https://docs.signar.com.ar/firmador" target="_blank" class="navbar-item">
        Documentación
      </a>
      <a href="https://soporte.lyris.com.ar" target="_blank" class="navbar-item">
        Soporte
      </a>
    </div>
    </div>
  </nav>
    <the-hero></the-hero>
    <features></features>
    <support></support>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHero from './components/TheHero.vue'
import Features from './components/Features.vue'
// import Benefits from './components/Benefits.vue'
// import Integration from './components/Integration.vue'
// import Download from './components/Download.vue'
import Support from './components/Support.vue'
import TheFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    TheHero,
    Features,
    // Benefits,
    // Integration,
    // Download,
    Support,
    TheFooter,
  }
}
</script>

<style lang="scss">
@import "./assets/scss/app.scss";
.logo {
  filter: grayscale(100%); // Make the image appear gray by default
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: none; // Remove the filter on hover to show the colored image
    scale: 1.1;
  }
}
// when .support-button is hover i want to do something
.support-button {
  &:hover {
    background-color: #fafafa;
    color: #3273dc;
  }
}
</style>
