<template>
<div>
  <div class="section has-background-primary">
    <div class="container has-text-centered has-text-white">
      <div class="columns is-centered">
        <div class="column is-10">
          <img src="@/assets/signar-white.svg" class="image is-centered" width="200" alt="Signar (R)">
          <br>
          <h1 class="is-2 title is-800 has-text-white">Nueva versión de nuestro firmador</h1>
          <p>
            En Lyris IT, nos enfocamos constantemente en mejorar la experiencia de los usuarios para brindar el mejor servicio posible, basándonos en el feedback de nuestros clientes. Relanzamos <b>Signar Firmador</b> en su nueva versión <b>Standalone</b>, con mejoras y nuevas características tecnológicas que fortalecen la seguridad.  
          </p>

        </div>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <h1 class="is-1 title has-text-centered has-text-primary">Integrable, eficiente, seguro</h1>
      <img src="@/assets/firmador-cycle.webp" class="image is-centered f-cycle"  alt="">
      <div class="columns">
        <div class="column">
            <h6 class="title is-4 has-text-primary has-text-centered is-800">Fácil integración en su organización</h6>
          <div class="content has-text-centered">
            <p>
              Integre firma digital de documentos electrónicos en los aplicativos de su organización de forma rápida y sencilla.
            </p>
          </div>
        </div>
        <div class="column">
            <h6 class="title is-4 has-text-primary has-text-centered is-800">Firme múltiples documentos de una sola vez</h6>
          <div class="content has-text-centered">
            <p>
              Optimice su tiempo al poder firmar varios documentos electrónicos de una sola vez.
            </p>
          </div>
        </div>
        <div class="column">
            <h6 class="title is-4 has-text-primary has-text-centered is-800">Sus documentos resguardados de forma segura en su nube</h6>
          <div class="content has-text-centered">
            <p>
              Signar Firmador almacena sus documentos en su nube una vez que los haya firmado.
            </p>
          </div>
        </div>
      </div>
      <br>
      <h1 class="is-3 title has-text-centered has-text-primary">Algunas características</h1>
      <br>
      <div class="columns is-centered has-text-centered">
        <div class="column">
          <div class="content">

          <p class="has-text-black"><i class="far fa-file-pdf fa-2x"></i>&nbsp;<i class="fas fa-code fa-2x"></i></p>
          <p>Soporte de diferentes tipos de firma: PDF y XML</p>
          </div>
        </div>
        <div class="column">
          <div class="content">
          <p class="has-text-black"><i class="far fa-copy fa-2x"></i></p>
          <p>Firma masiva de documentos</p>
         </div>
        </div>
        <div class="column">
          <div class="content">
          <p class="has-text-black"><i class="fas fa-arrows-alt fa-2x"></i>&nbsp;<i class="far fa-2x fa-file"></i></p>
          <p>Elección de ubicaciones de la firma</p>
         </div>
        </div>
        <div class="column">
          <div class="content">
          <p class="has-text-black"><i class="fas fa-key fa-2x"></i>&nbsp;<i class="fas fa-user-shield fa-2x"></i></p>
          <p>Solicita contraseña al firmar, mejorando la seguridad</p>
         </div>
        </div>
        <div class="column">
          <div class="content">
          <p class="has-text-black"><i class="fas fa-signature fa-2x"></i>&nbsp;<i class="fas fa-edit fa-2x"></i></p>
          <p>Agregado de texto personalizado</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.f-cycle{
  margin-bottom: 60px !important;
  margin-top: 60px !important;
}
</style>