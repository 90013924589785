<template>
  <div class="hero is-fullheight-with-navbar is-white">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-4 is-clearfix logo-container">
            
            <img src="../assets/feather.webp" class="image the-pluma" alt="Pluma">
            <div class="has-text-centered">
              <img src="@/assets/firmador-lyris-signar-title-2.webp" class="image is-centered is-hidden-tablet" width="200" alt="">
              <br>
              <h1 class="title is-3 is-spaced titulo is-hidden-tablet" >Ahora es más fácil<br>firmar documentos electrónicos</h1>
            </div>
          </div>
          <div class="column has-text-centered-mobile has-text-left is-7">
            <!-- <img src="@/assets/firmador.svg" class="image is-hidden-mobile" width="64" alt=""> -->
            <img src="@/assets/firmador-lyris-signar-title-2.webp" class="image is-hidden-mobile" width="250" alt="">
            <br>
            <h1 class="title is-2 is-spaced titulo is-hidden-mobile" >Ahora es más fácil firmar documentos electrónicos <i class="fas fa-check has-text-primary"></i></h1>
            <h3 class="subtitle is-5 has-text-primary ">Con <b>Signar Firmador Standalone</b>, usá Firma Digital o Firma Electrónica, según Ley 25.506</h3>
            <div class="columns"></div>
            <div class="field">
                <label class="label">Seleccione su sistema operativo</label>
                <div class="columns">
                  <div class="column">
                    <div class="select is-fullwidth">
                      <select v-model="selected">
                        <option value="win64">Windows 7, 8, 10 (64 bits)</option>
                        <option value="win32">Windows 7, 8, 10 (32 bits)</option>
                        <option value="linux">Linux</option>
                      </select>
                    </div>
                  </div>
                  <div class="column">
                    <p><a :href="getDownloadLink" target="_blank" class="button is-black"><i class="fas fa-download"></i>&nbsp;Descargar</a></p>
                  </div>
                </div>
            </div>
            <br>
            <!-- <p><a href="https://lyris.com.ar/firmador" target="_blank" class="button is-small is-dark is-outlined"><i class="fas fa-download"></i>&nbsp;Versión legacy (JNLP)</a></p>
            <br> -->
            <h3 class="subtitle is-6"><i class="fas fa-mobile"></i>&nbsp;Utilice <b>Signar Firmador Mobile</b>, si desea firmar desde la comodidad de la palma de su mano</h3>
            <p><a href="https://play.google.com/store/apps/details?id=ar.com.helenasoftware.signar" target="_blank"><img src="/google-play.svg" width="185" class="image mb-2 is-centered-mobile is-hidden-touch" /></a></p>
            <p><a href="https://play.google.com/store/apps/details?id=ar.com.helenasoftware.signar" target="_blank"><img src="/google-play.svg" width="185" class="image mb-2 is-centered-mobile is-hidden-desktop is-centered" /></a></p>
            <p class="is-size-7">* Sólo disponible para Android</p>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      selected: 'win64'
    }
  },
  computed: {
    getDownloadLink: function (){
      let value
      switch(this.selected){
        case 'win64':
          value = 'https://files.lyris.com.ar/signarFirmador/installer/SignarFirmador-Installer.exe'
          break;
        case 'win32':
          value = 'https://files.lyris.com.ar/signarFirmador/installer/SignarFirmador-Installer-32bits.exe'
          break;
        case 'linux':
          value = '/SignarLinux.zip'
          break;
      }
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-container{
  display:flex;
  align-items: center;
}
.titulo {
  line-height: 4.5rem;
}
@media screen and (max-width: 768px){
  .the-pluma {
    height: 300px;
    float: left;
  }
  .titulo {
    line-height: 2.5rem;
  }
}
@media screen and (max-width: 599px){
  .the-pluma {
    height: 200px;
    float: left;
  }
  .titulo {
    font-size: 1.5rem !important;
  }
}
</style>